import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'g',
      element: (
        <GuestGuard>
          <DashboardLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: 'home',
          element: <Navigate to="/" />,
        },
      ],
    },
    {
      path: 'auth',
      children: [
        {
          path: 'reset-password/:resetToken',
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: '',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Home />, index: true },
        {
          path: 'games/:categSlug/:companyId',
          element: <Games />,
        },
      ],
    },

    // New routes here
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <Navigate to="/" /> },
        {
          path: 'home/:paymentMethod',
          element: <Home />,
        },
        { path: 'history', element: <TransactionHistory /> },
        { path: 'bet-history', element: <BetHistory /> },
      ],
    },

    // User Routes
    {
      path: 'user',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/user/account', element: <AccountProfile /> },
        { path: '/user/banks', element: <MemberBankList /> },
      ],
    },

    // Referral Route
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/referral', element: <Referral /> },
        { path: '/referral/:tab', element: <Referral /> },
        { path: '/referral/refer-a-friend', element: <ReferAFriend /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// Home
const Home = Loadable(lazy(() => import('../views/Home/Home')));

// Games
const Games = Loadable(lazy(() => import('../views/Games')));

// Transaction History
const TransactionHistory = Loadable(lazy(() => import('../views/Home/TransactionHistory')));

// Bet History
const BetHistory = Loadable(lazy(() => import('../views/Home/BetHistory/BetHistory')));

// USER
const AccountProfile = Loadable(lazy(() => import('../views/Home/user/AccountProfile')));
const MemberBankList = Loadable(lazy(() => import('../views/Home/user/MemberBankList')));

// REFERRAL
const Referral = Loadable(lazy(() => import('../views/referral/Referral')));
const ReferAFriend = Loadable(lazy(() => import('../views/referral/ReferAFriend')));

// PAYMENT STATS
const PaymentStatus = Loadable(lazy(() => import('../pages/PaymentStatus')));

// MAIN
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
