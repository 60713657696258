import { useTranslation } from 'react-i18next';
import useSettings from './useSettings';
// config
import { allLangs, defaultLang } from '../config';
import { fToSnake } from '../utils/formatString';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const { onChangeDirectionByLang } = useSettings();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || process.env.REACT_APP_LOCALE;

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
    onChangeDirectionByLang(newlang);
  };

  const isTranslationExist = (text) => {
    if (!text) return false;

    return i18n.exists(text);
  };


  const handleTranslate = (text, options) => {
    let translated = text;

    const objKey = fToSnake(translated);

    if (i18n.exists(objKey)) {
      translated = translate(objKey, options);
    }

    return translated;
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate: handleTranslate,
    currentLang,
    allLangs,
    isTExists: isTranslationExist,
  };
}
